* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    /* background-color: #121212; */
    /* background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(6, 8, 18, 1) 0%,
        rgba(0, 0, 22, 1) 100%
    ); */
    background-image: linear-gradient(
        to right bottom,
        #030012,
        #040115,
        #050117,
        #07021a,
        #08021c,
        #08021d,
        #08021d,
        #08021e,
        #08011d,
        #07011c,
        #07001b,
        #06001a
    );
}
