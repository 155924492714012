.Navbar ul {
    /* border: 1px solid white; */
    position: fixed;
    display: flex;
    padding-top: 10px;
    padding-bottom: 5px;
    height: 10px;
    justify-content: center;
    overflow: hidden;
    animation: navShow 0.5s 0s forwards;
    width: 100%;
    background: rgba(0, 0, 0, 0.658);
    z-index: 10;
}

@keyframes navShow {
    0% {
        height: 0px;
    }
    100% {
        height: 60px;
    }
}

.Navbar li {
    padding: 10px 20px;
    list-style: none;
    text-decoration: none;
    font-size: 1.2rem;
    border-bottom: 0px solid white;
    transition: all 0.2s ease;
}

.li_bottom {
    width: 0%;
    height: 3px;
    margin-top: 5px;
    background-color: #0079db;
    transition: all 0.3s;
}

.Navbar li:hover {
    cursor: pointer;
    font-weight: 700;
}

.Navbar li:hover .li_bottom {
    width: 100%;
    height: 3px;
    margin-top: 5px;
}
