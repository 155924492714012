.section-container {
    height: auto;
    width: 80%;
    position: relative;
    justify-content: center;
}

.section-container h1 {
    margin: 3rem;
    margin-bottom: 1rem;
}

.section-container p {
    padding-left: 6rem;
    font-size: 1.6rem;
    text-align: left;
    padding-bottom: 1rem;
}

@media (max-width: 1200px) {
    .section-container {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .section-container {
        width: 90%;
    }
    .section-container h1 {
        margin: 3rem;
        font-size: 1.7rem;
        margin-bottom: 1rem;
    }
    .section-container p {
        padding-left: 4.3rem;
        font-size: 1.4rem;
        padding-bottom: 0.8rem;
    }
}

@media (max-width: 650px) {
    .section-container {
        width: 90%;
    }
    .section-container h1 {
        margin: 2rem;
        font-size: 1.7rem;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }
    .section-container p {
        padding-left: 4rem;
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 495px) {
    .section-container {
        width: 90%;
    }
    .section-container h1 {
        margin: 1rem;
        font-size: 1.7rem;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }
    .section-container p {
        padding-left: 2.3rem;
        font-size: 1.1rem;
        padding-bottom: 0.5rem;
        text-overflow: initial;
    }
}

@media (max-width: 350px) {
    .section-container {
        width: 90%;
    }
    .section-container h1 {
        margin: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        margin-left: 0rem;
    }
    .section-container p {
        padding-left: 1.3rem;
        font-size: 1rem;
        padding-bottom: 0.5rem;
        text-overflow: initial;
    }
}
