.div-container {
    width: 100%;
    height: calc(100vh);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.div-center {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titulo {
    text-align: center;
    font-size: 6rem;
    padding: 10rem 2rem;
}

.media-links {
    width: 25%;
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-evenly;
}

.media-links svg {
    width: 40px;
    fill: white;
    transition: all 0.3s;
}

.media-links svg:hover {
    cursor: pointer;
    fill: #0079db;
}

.transition {
    color: #0079db;
}

.arrowDown {
    width: 50px;
    justify-self: start;
    animation: animationArrow 2s 0s infinite;
    transition: all 0.3s;
    cursor: pointer;
}

canvas {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 110vh;
    z-index: -1;
}

.arrowDown:hover {
    color: #0079db;
}

@keyframes animationArrow {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0px);
        color: #0079db;
    }
    100% {
        transform: translateY(-10px);
    }
}

@media (max-width: 1200px) {
    .titulo {
        font-size: 5rem;
        padding: 10rem 2rem;
        min-height: 490px;
    }
    .media-links {
        width: 40%;
        height: 40px;
    }

    .media-links svg {
        width: 40px;
    }
}
@media (max-width: 910px) {
    .titulo {
        font-size: 4rem;
        padding: 10rem 2rem;
        min-height: 480px;
    }

    .media-links {
        width: 50%;
        height: 40px;
    }

    .media-links svg {
        width: 35px;
    }
}
@media (max-width: 650px) {
    .titulo {
        text-align: center;
        font-size: 3rem;
        min-height: 384px;
        padding: 8rem 2rem;
    }

    .media-links {
        width: 70%;
        height: 40px;
    }

    .media-links svg {
        width: 35px;
    }
}

@media (max-width: 420px) {
    .titulo {
        text-align: center;
        font-size: 2.5rem;
        min-height: 350px;
        padding: 7rem 2rem;
    }

    .media-links {
        width: 90%;
        height: 40px;
    }

    .media-links svg {
        width: 30px;
    }
}
