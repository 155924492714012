.modal {
    display: block;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
  }
  
  .sections-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }

  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }

  .project-card {
    width: 300px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 20px;
    margin: 10px !important;
    background-color: rgba(41, 29, 214, 0.418);
    cursor: pointer;
  }

  .project-card .name {
    padding-left: 0px;
  }

  .project-card .description {
    padding-left: 10px;
    font-size: 1.2rem;
  }
  .project-card .tech {
    padding-left: 10px;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .project-card .tech li {
    margin: 5px 15px;
  }